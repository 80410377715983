<template>
  <CForm @submit.prevent="checkForm" method="POST">
    <CCard>
      <CCardBody>
        <h3>
          <CIcon name="cil-user"></CIcon>
          {{ operationName }} Account
        </h3>
        <CAlert v-if="message" :color="alertType" fade> {{ message }}</CAlert>
        <CRow>
          <CCol col="10"></CCol>
          <CCol class="text-right">
            <div><label>Active</label></div>
            <CSwitch color="success" size="lg" :checked.sync="account.active">
            </CSwitch>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="10"></CCol>
          <CCol class="text-right">
            <div><label>Admin</label></div>
            <CSwitch
              color="success"
              size="lg"
              :checked.sync="account.isAdmin"
            ></CSwitch>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              label="First Name"
              type="text"
              v-model="account.firstName"
            />
          </CCol>
          <CCol>
            <CInput label="Last Name" type="text" v-model="account.lastName" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput label="Email" type="text" v-model="account.email" />
          </CCol>
          <CCol>
            <CInput
              label="Mobile Number"
              type="text"
              v-model="account.mobileNumber"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              label="Password"
              type="password"
              v-model="account.password"
            />
          </CCol>
          <CCol>
            <CInput
              label="Confirm Password"
              type="password"
              v-model="account.confirmPassword"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div><label>Picture</label></div>
            <input
              id="file"
              ref="file"
              type="file"
              @change="handleFileUpload"
            />
          </CCol>
          <CCol>
            <h4><CIcon name="cil-briefcase"></CIcon> Partners</h4>
            <div
              class="form-check form-check-inline"
              v-for="(partner, k) in partners"
              :key="k"
            >
              <input
                :id="partner.id"
                :value="partner.id"
                name="partner"
                type="checkbox"
                v-model="account.partners"
              />
              <label :for="partner.name" style="padding-left: 10px">{{
                partner.name
              }}</label>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <br />
            <br />
            <div>
              <img
                :src="account.picture"
                class="img-thumbnail"
                style="max-height: 200px"
              />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="9"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton
              color="secondary"
              class="btn-lg"
              @click="goBack"
              style="margin-right: 15px"
              ><CIcon name="cil-list" /> Back to the list</CButton
            >

            <CLoadingButton
              :disabled="loading"
              color="primary"
              type="submit"
              class="btn-lg"
              ><CIcon name="cil-save" /> Save</CLoadingButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
export default {
  name: "CreateAccount",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),

      emptyGuid: "00000000-0000-0000-0000-000000000000",
      partners: [],
      account: {
        id: "00000000-0000-0000-0000-000000000000",
        firstName: null,
        lastName: null,
        email: null,
        mobileNumber: null,
        password: null,
        confirmPassword: null,
        partners: [],
        active: true,
        isAdmin: false,
        picture: null,
        file: null,
      },
      loading: false,
      // Alert
      alertType: "success",
      message: null,
    };
  },
  methods: {
    checkForm: function (e) {
      if (this.loading) return;
      this.loading = true;


      if (
        !this.account.email ||
        (this.account.id == this.emptyGuid && !this.account.password) || 
        (this.account.id == this.emptyGuid && !this.account.confirmPassword) || 
        !this.account.mobileNumber ||
        !this.account.firstName ||
        !this.account.lastName
      ) {
        this.failed = true;
        this.message = "Please enter all needed fields.";
        this.loading = false;
                  this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
        return;
      }
      this.save();
    },
    handleFileUpload(event) {
      this.account.file = event.target.files[0];
    },
    getPartners() {
      let self = this;
      axios.get(this.$apiAdress + "/v1/partner")
        .then(function (response) {
          self.partners = response.data;
        })
        .catch(function (error) {
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;
      let form = new FormData();
      form.append("id", self.account.id);
      form.append("email", self.account.email);
      form.append("password", self.account.password);
      form.append("mobileNumber", self.account.mobileNumber);
      form.append("firstName", self.account.firstName);
      form.append("lastName", self.account.lastName);
      form.append("active", self.account.active);
      form.append("hasGlobalRights", self.account.isAdmin);
      form.append("file", self.account.file);
      for (var i = 0; i < self.account.partners.length; i++) {
        form.append("partners[]", self.account.partners[i]);
      }
      axios
        .post(this.$apiAdress + "/v1/Account", form)
        .then((response) => {
          self.account.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated account.";
          self.account.password = null;
          self.account.confirmPassword = null;
        })
        .catch(function (error) {
          if (error.response.data.message == "The given data was invalid.") {
            self.message = null;
            for (let key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                self.message += error.response.data.errors[key][0] + "  ";
              }
            }
            self.loading = false;
          } else {
            self.loading = false;
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          }
        });
    },
    getAccount(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Account/" + id)
        .then(function (response) {
          self.account = response.data;
          self.account.isAdmin = response.data.hasGlobalRights;
        })
        .catch(function (error) {
          this.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    this.getPartners();
    // Edit OR Create
    if (self.$route.params.id != this.emptyGuid) {
      self.getAccount(self.$route.params.id);
      self.operationName = "Edit";
    }
  },
};
</script>
